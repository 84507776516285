<template>
<!--  <div style="word-break: keep-all; padding: 0 4px;">
    {{ this.makeStr() }}
  </div>-->
  <div class="include-group" >
    <span
        style="word-break: keep-all; display: inline-block;"
        v-for="row in group"
        :key="row.mk_group_srl"
    >
      {{row.gname}}
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
//import axios from "axios";

export default Vue.extend({
      name: "groupCell",
      data: function () {
        return {
          row: {},
          group: [],

        };
      },

      beforeMount() {
        this.row = this.getValueToDisplay(this.params)

        this.newGroupList()
      },

      methods: {

        newGroupList() {
          if (this.row.groups.length > 0) {
            this.group = this.row.groups
          }
        },

        makeStr() {
          let tmpStr = ''

          for (let i = 0; i < this.group.length; i++) {
            tmpStr += this.group[i].gname + ', '
          }

          return tmpStr.slice(0, -2)
        },

        getValueToDisplay(params) {
          return params.data
        },
      },
    }
)
</script>

<style scoped>
.include-group span{background: #ebebe2; padding: 2px 5px; border-radius: 20px; font-size: 14px; white-space: nowrap; line-height: 20px; margin-right: 8px;}
</style>
